<template>
  <div :class="classInput">
    <div class="form-group">
      <label class="label" :class="{'required': isRequired, 'is-invalid': isInvalid}">{{ label }}</label>
      <div class="inline-radio">
        <div v-for="opt in options" :key="opt">
            <input
              type="radio"
              name="radio"
              :id="'radio'+opt.value"
              :value="opt.value"
              class="my10x5"
              @blur="() => validate()"
              @change="$emit('input', $event.target.value)"
            >
            <label :for="'radio'+opt.value">{{ opt.label }}</label>
        </div>
      </div>
      <div
        v-if="isInvalid"
        class="invalid-feedback"
      >
        <span v-if="isInvalid">Campo obrigatório.</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true,
      validator (opts) {
        return !opts.find(opt => typeof opt !== 'object')
      }
    },
    value: {
      type: String,
      required: true
    },
    classInput: {
      type: String,
      required: true
    },
    isRequired: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isInvalid: false,
      hasTouched: false
    }
  },
  methods: {
    validate () {
      this.isInvalid = (this.hasTouched && this.value === null) || (this.isRequired && !this.value)
    },
    isValid () {
      this.validate()
      return !this.isInvalid
    }
  }
}
</script>
<style scoped>
.my10x5 {
  margin: 10px 5px;
}
.inline-radio {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
}
</style>
